/* Existing Styles */
/* Gradient Animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

html, body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  height: 200%;
  background-color: #111;
}

.full-width-segment {
  max-width: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px 0;
}

.landing-page {
  position: relative;
  text-align: center;
  font-size: 19px;
  padding: 54px 12px;
  background-color: #ffffff;
  overflow: hidden; /* Ensure the video stays within the bounds */
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  pointer-events: none; /* Prevent interaction with the video */
}

.text-container {
  margin-bottom: 50px;
  position: relative; /* Ensure it stays above the video */
  z-index: 1;
}

.text-container h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.text-container p {
  font-size: 16px;
  margin-bottom: 20px;
}

.link {
  background-color: #ff5722;
  color: white;
  padding: 12px 23px;
  text-decoration: none;
  border-radius: 5px;
  display: inline-block;
}

.link:hover {
  background-color: #e64a19;
}

.stats-container {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
  position: relative; /* Ensure it stays above the video */
  z-index: 1;
}

.stat {
  background-color: rgb(253, 253, 253);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  text-align: center;
  width: 250px;
}

.stat h2 {
  font-size: 29px;
  margin: 0 0 10px 0;
}

.how-it-helps {
  text-align: center;
  padding: 50px 0;
  background-color: #000000;
}

.how-it-helps h2 {
  font-size: 50px;
  margin-bottom: 36px;
  background: linear-gradient(270deg, #ff1493, #00ff7f, #1e90ff, #ff4500);
  background-size: 800% 800%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimation 5s ease infinite;
}

.help-cards {
  display: flex;
  justify-content: center;
  gap: 22px;
  flex-wrap: wrap;
}

.help-card {
  background-color: rgb(255, 255, 255);
  padding: 23px;
  border-radius: 11px;
  box-shadow: 0 0 19px rgba(0,0,0,0.1);
  text-align: center;
  width: 200px;
}

.help-card h3 {
  font-size: 22px;
  margin: 10px 0;
}

.help-card p {
  font-size: 17px;
}

.partners-section {
  text-align: center;
  padding: 80px 0;
  background-color: #f7f7f7;
}

.partners-logos {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.partners-logos img {
  width: 60px;
  height: auto;
}

.footer {
  background-color: #181818;
  padding: 50px 0;
  color: white;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px;
}

.footer-logo {
  text-align: center;
}

.footer-logo img {
  width: 80px;
}

.footer-links {
  display: flex;
  text-align: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.footer-links div {
  margin: 10px;
}

.footer-links h3 {
  margin-bottom: 10px;
}

.footer-links a {
  color: white;
  text-decoration: none;
  margin: 5px 0;
  display: block;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer p {
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
  }

  .footer-links div {
    margin: 20px 0;
  }

  .text-container h1 {
    font-size: 28px;
  }

  .text-container p {
    font-size: 14px;
  }

  .stat {
    width: 90%;
  }

  .how-it-helps h2 {
    font-size: 36px;
  }

  .help-card {
    width: 90%;
  }

  .partners-logos img {
    width: 40px;
  }

  .footer-logo img {
    width: 60px;
  }
}

@media (max-width: 480px) {
  .text-container h1 {
    font-size: 24px;
  }

  .text-container p {
    font-size: 12px;
  }

  .link {
    padding: 10px 20px;
    font-size: 14px;
  }

  .how-it-helps h2 {
    font-size: 28px;
  }

  .help-card {
    width: 100%;
  }

  .partners-logos img {
    width: 30px;
  }

  .footer-logo img {
    width: 50px;
  }
}

/* New Homepage Styles */
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.homepage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ensure the homepage covers the full viewport height */
  text-align: center;
  background-color: #111; /* Adjust background color */
  color: white;
}

.homepage-title {
  font-size: 3em;
  font-weight: bold;
  margin: 0.5em 0;
}

.homepage-subtitle {
  font-size: 2em;
  margin-top: 1em;
  font-weight: lighter;
  margin: 0.5em 0;
  font-weight: bold;
  background: linear-gradient(270deg, #ff1493, #00ff7f, #1e90ff, #ff4500);
  background-size: 800% 800%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimation 6s ease infinite;
}

.homepage-description {
  font-size: 1.2em;
  color: #aaa;
}

.homepage-button {
  background-color: #ff5722;
  color: white;
  border: none;
  padding: 1em 2em;
  font-size: 1.2em;
  cursor: pointer;
  margin-top: 1em;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.homepage-button:hover {
  background-color: #e64a19;
}

.footer-hirespoof {
  font-size: 4em;
  font-weight: lighter;
  margin: -0.39em 0;
  background: linear-gradient(270deg, #e5e1e3, #9d9ea0, #727476, #362b27);
  background-size: 1000% 1000%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimation 3s ease infinite;
}

.footer-bottom {
  font-size: 1em;
  font-weight: lighter;
  margin: 1.3em 0;
  margin-bottom: 1.5em;
  background-color: #181818;
}

/* Banner Section */
.banner-section {
  text-align: center;
  padding: 20px;
}

.banner-image {
  max-width: 100%;
  height: auto;
}
/* Flash Deals Section */
.flash-deals {
  margin: 20px;
  display: flex;
  justify-content: flex-start; /* Align cards to the start, similar to your example */
  gap: 20px; /* Add space between the cards */
  overflow-x: auto; /* Allow horizontal scrolling */
}

.video-deals {
  margin: 20px;
  display: flex;
  justify-content: flex-start; /* Align cards to the start, similar to your example */
  gap: 40px; /* Add space between the cards */
  overflow-x: auto; /* Allow horizontal scrolling */
}

.video-card {
  background-color: #6439ff;
  color: rgb(242, 234, 234);
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex: 0 0 auto; /* Prevent flex-grow, cards keep their size */
  width: 280px; /* Set a fixed width for each card */
  margin-bottom: 40px; /* Add margin at the bottom for vertical spacing */
  margin-right: 250px; /* Add margin at the bottom for vertical spacing */

}

.flash-deals h2 {
  text-align: center;
  color: #ff1493;
  
  font-size: 2em;
  margin-bottom: 20px;
  background: linear-gradient(270deg, #ff1493, #00ff7f, #1e90ff, #ff4500);
  background-size: 800% 800%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 5s ease infinite;
  width: 100%; /* Ensure the heading spans the full width */
}

.deal-card {
  background-color: #060606;
  color: rgb(242, 234, 234);
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex: 0 0 auto; /* Prevent flex-grow, cards keep their size */
  width: 250px; /* Set a fixed width for each card */
  margin-bottom: 20px; /* Add margin at the bottom for vertical spacing */
}

.deal-card h3 {
  font-size: 1.0em;
  margin-bottom: 10px;

}

.deal-card p {
  font-size: 1em;
  margin-bottom: 15px;

}

.deal-card a {
  color: #ff5722;
  text-decoration: none;
  font-weight: bold;


}

.copy-link{
  background-color: #13c62e;
  border: none;
  color: rgb(255, 253, 253);
  padding: 9px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-style:none;
  margin: 2px 1px;

  
}

.deal-content {
  font-size: 0.8em;
  margin-bottom: 15px;
  color: #ffffff;
  color: #231616;


}

.deal-card a:hover {
  color: #e64a19;
}

.deal-actions {
  display: center;
}

/* Responsive design for Flash Deals */
@media (max-width: 768px) {
  .flash-deals {
      flex-wrap: wrap; /* Allow wrapping on smaller screens */
  }

  .deal-card {
      width: 45%; /* Cards take up 45% of the container on smaller screens */
  }
  .video-card {
    width: 45%; /* Cards take up 45% of the container on smaller screens */

  }

  .flash-deals h2 {
      font-size: 1.5em;
  }

  .deal-card h3 {
      font-size: 1.2em;
  }

  .deal-card p {
      font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .deal-card {
      width: 100%; /* Full width on very small screens */
      margin-right: 0;
  }

  .flash-deals h2 {
      font-size: 1.2em;
  }

  .deal-card h3 {
      font-size: 1em;
  }

  .deal-card p {
      font-size: 0.8em;
  }
}

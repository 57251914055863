.partners-section {
    text-align: center;
    padding: 70px 0;
    background-color: #f7f7f7;
    overflow: hidden; /* Ensure logos don't overflow the container */
  }
  
  .partners-logos {
    display: flex;
    justify-content: flex-start; /* Align items to the start for animation */
    gap: 20px;
    margin-top: 20px;
    animation: move-logos 15s linear infinite; /* Apply animation */
  }
  
  .partners-logos img {
    width: 60px;
    height: auto;
  }
  
  @keyframes move-logos {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
/* src/pages/DealList.css */
.deal-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
  }
  
  .deal-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    max-width: 300px;
    text-align: center;
    background-color: #f9f9f9;
  }
  
  .deal-text {
    margin-bottom: 8px;
  }
  
  .deal-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 8px;
  }
  
  .deal-link {
    display: inline-block;
    margin-top: 8px;
    color: #007bff;
    text-decoration: none;
  }
  
  .deal-link:hover {
    text-decoration: underline;
  }
  
  .deal-details {
    padding: 20px;
  }
  
  .back-link {
    display: inline-block;
    margin-top: 20px;
    color: #007bff;
    text-decoration: none;
  }
  
  .back-link:hover {
    text-decoration: underline;
  }
  
/* AboutUs.css */

.about-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .about-us-container h2 {
    font-size: 2.5rem;
    color: #f0eeee;
  }
  
  .about-us-container p {
    font-size: 1.1rem;
    color: #eac4c4;
    margin-bottom: 15px;
  }
  